import { useContext, useEffect, useState, useRef } from 'react'
import { useResizeObserver } from 'usehooks-ts'
import FocusTrap from 'focus-trap-react'

import { type SanityImageFragment } from '@data/sanity/queries/types/image'
import {
  type SanityHeaderSettings,
  type SanitySiteFragment,
} from '@data/sanity/queries/types/site'
import { SiteContext } from '@lib/site-context'
import { StringsContext } from '@lib/strings-context'

import Menu from '@blocks/navigation/menu'
import MegaNavigation from '@blocks/navigation/mega-navigation'
import ProductSearchDrawer from '@blocks/navigation/product-search-drawer'
import CartToggle from '@components/cart-toggle'
import PromoBar from '../promo-bar'
import Logo from './logo'
import MobileMenu from './mobile-menu'
import MobileMenuToggle from './mobile-menu-toggle'
import ProductSearchToggle from '@blocks/navigation/product-search-toggle'

export interface HeaderSizeValues {
  height: number
}

type HeaderProps = Pick<SanitySiteFragment, 'promoSettings'> &
  SanityHeaderSettings & {
    isInView: boolean
    logo?: SanityImageFragment
    logoSymbol?: SanityImageFragment
    logoSwap?: boolean
    onResize?: (newValues: HeaderSizeValues) => void
  }

const Header = ({
  promoSettings,
  menuDesktopLeft,
  menuDesktopRight,
  menuMobilePrimary,
  menuMobileSecondary,
  logo,
  logoSymbol,
  logoSwap,
  onResize,
}: HeaderProps) => {
  const strings = useContext(StringsContext)
  const { toggleMegaNavigation, promoHeight } = useContext(SiteContext)

  const [isLoaded, setIsLoaded] = useState(false)
  const [isLogoSymbol, setIsLogoSymbol] = useState(logoSwap)

  const headerHeightRef = useRef<number>()
  const headerRef = useRef<HTMLDivElement | null>(null)
  const headerRectangle = useResizeObserver({
    ref: headerRef,
  })

  // Wait for intersection observer to load, then show header
  useEffect(() => {
    if (!isLoaded) {
      setTimeout(() => setIsLoaded(true), 200)
    }
  }, [isLoaded])

  useEffect(() => {
    if (!isLoaded) {
      return
    }

    if (
      headerRectangle.height &&
      headerHeightRef.current !== headerRectangle.height
    ) {
      headerHeightRef.current = headerRectangle.height

      if (onResize) {
        onResize({
          height: headerRectangle.height,
        })
      }
    }
  }, [headerRectangle, isLoaded, onResize])

  useEffect(() => {
    const heroLogo = document.getElementById('hero-logo')

    if (!heroLogo || !logoSwap) {
      return
    }

    const handleScroll = () => {
      const headerHeight = headerRectangle?.height ?? 0
      const heroLogoTop = heroLogo.offsetTop
      const heroLogoHeight = heroLogo.clientHeight
      const scrollY = window.scrollY
      const padding = 20

      setIsLogoSymbol(
        scrollY + headerHeight <=
          heroLogoTop + heroLogoHeight + headerHeight + promoHeight - padding,
      )
    }

    window.addEventListener('scroll', handleScroll)

    handleScroll()

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [logoSwap, headerRectangle])

  return (
    <>
      <a
        href="#content"
        className="block fixed top-0 left-1/2 transform -translate-x-1/2 -translate-y-full z-90 px-2 py-1 bg-pageBG text-pageText text-xs font-semibold uppercase focus:translate-y-0 focus:outline-none"
      >
        {strings.skipToContent}
      </a>

      {promoSettings?.enabled && (
        <PromoBar
          enabled={promoSettings.enabled}
          display={promoSettings.display}
          text={promoSettings.text}
          link={promoSettings.link}
        />
      )}

      <MobileMenu
        menuMobilePrimary={menuMobilePrimary}
        menuMobileSecondary={menuMobileSecondary}
      />

      <header className="sticky top-0 inset-x-0 z-50 bg-header-bg text-header-text">
        <div ref={headerRef} className="relative">
          <div className="container grid grid-cols-[1fr,10rem,1fr] items-center w-full py-5 border-b border-gray relative z-40">
            <div className="flex items-center">
              <MobileMenuToggle className="lg:hidden" />

              {/* Desktop Header Menu Left */}
              <nav className="hidden lg:flex">
                {!!menuDesktopLeft?.items && (
                  <Menu
                    id={menuDesktopLeft.slug.current}
                    items={[...menuDesktopLeft.items]}
                    onClick={() => toggleMegaNavigation(false)}
                    useMegaNav
                    isHeaderDesktopMenu
                  />
                )}
              </nav>
            </div>

            {/* Logo */}
            <Logo
              logo={logo}
              logoSymbol={logoSymbol}
              isLogoSymbol={isLogoSymbol}
            />

            {/* Desktop Header Menu Right */}
            <nav className="flex items-center justify-end gap-x-4 lg:gap-x-6">
              <ProductSearchToggle className="text-xs uppercase">
                {strings.searchLabel}
              </ProductSearchToggle>

              {!!menuDesktopRight?.items && (
                <Menu
                  id={menuDesktopRight.slug.current}
                  items={menuDesktopRight.items}
                  onClick={() => toggleMegaNavigation(false)}
                  useMegaNav
                  isHeaderDesktopMenu
                  className="hidden lg:flex items-center"
                />
              )}

              <CartToggle
                hasCartCount
                hasCartIcon
                className="text-xs uppercase"
              >
                {strings.cartTitle}
              </CartToggle>
            </nav>
          </div>

          <MegaNavigation
            items={[
              ...(menuDesktopLeft?.items ?? []),
              ...(menuDesktopRight?.items ?? []),
            ]}
            headerHeight={headerRectangle?.height ?? 0}
          />
          <ProductSearchDrawer headerHeight={headerRectangle?.height ?? 0} />
        </div>
      </header>
    </>
  )
}

export default Header
